<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col sm="12" class="package-details-name">
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('users_details.account_details') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.photo') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <b-avatar variant="" size="64">
          <img
            v-if="dataEmployee.photo && dataEmployee.photo.file_name"
            :src="dataEmployee.photo.full_path + dataEmployee.photo.file_name"
          />
          <default-user-image-icon v-else />
        </b-avatar>
      </b-col>
    </b-row>
    <!-- <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.id') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">#{{ dataEmployee.id }}</p>
      </b-col>
    </b-row> -->
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.name') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p v-if="dataEmployee.subtype == 'business'" class="mb-0">
          {{ dataEmployee.authorized_person_name }}
        </p>
        <p v-if="dataEmployee.subtype == 'employee'" class="mb-0">
          {{ dataEmployee.name }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_details.subtype') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p
          v-if="dataEmployee.subtype == 'employee'"
          class="mb-0 d-flex align-items-center"
        >
          <subtype-employee-icon class="mr-2" />
          {{ $t('users_details.employee') }}
        </p>
        <p
          v-if="dataEmployee.subtype == 'business'"
          class="mb-0 d-flex align-items-center"
        >
          <subtype-business-icon class="mr-2" />
          {{ $t('users_details.business') }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.employee_type"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.type') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p v-if="dataEmployee.employee_type == 1" class="mb-0">
          {{ $t('users_data.freelance_recruitment_expert') }}
        </p>
        <p v-if="dataEmployee.employee_type == 2" class="mb-0">
          {{ $t('users_data.agency_specialized') }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.job_title" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">
          {{
            dataEmployee.employee_type == 1
              ? $t('users_data.job_title')
              : $t('users_data.entity_name')
          }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.job_title }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.created_at') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ formatedDate(dataEmployee.created_at) }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.status != 2" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.status') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <div class="d-flex">
          <b-badge
            pill
            :variant="'light-' + statusList[1][dataEmployee.status]"
            :class="'border-' + statusList[1][dataEmployee.status]"
            class="text-reg-12 py-0 px-3"
            >{{ $t('common.' + statusList[0][dataEmployee.status]) }}</b-badge
          >
        </div>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.email') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.email }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.phone') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0" dir="ltr">
          {{ dataEmployee.phone }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.country') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ jsonParseCountry(dataEmployee.country) }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.city') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ jsonParseCountry(dataEmployee.city) }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.authorized_person_name"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.commercial_name') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.name }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.commercial_registration_no"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">
          {{ $t('personal_information.commercial_registration_no') }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.commercial_registration_no }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.tax_number" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.tax_number') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.tax_number }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.company_size" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.company_size') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{
            companySizeList.filter(
              (item) => item.id === dataEmployee.company_size
            )[0][`name_` + $route.params.lang]
          }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.number_employees"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.number_employees') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.number_employees }}
          <span class="mb-0">
            {{ $t('users_details.employee') }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.language_skills"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.language_skills') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value d-flex">
        <b-badge
          v-for="(language, index) in dataEmployee.language_skills"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{
            languageSkillsList.filter((item) => item.id === language)[0][
              `name_` + $route.params.lang
            ]
          }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p v-if="dataEmployee.subtype == 'business'" class="mb-0">
          {{ $t('personal_information.industry_preference') }}:
        </p>
        <p v-if="dataEmployee.subtype == 'employee'" class="mb-0">
          {{ $t('personal_information.industry_preference_employee') }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value d-flex">
        <b-badge
          v-for="(item, index) in dataEmployee.industry_preference"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{ item.name }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p v-if="dataEmployee.subtype == 'business'" class="mb-0">
          {{ $t('personal_information.role_preference') }}:
        </p>
        <p v-if="dataEmployee.subtype == 'employee'" class="mb-0">
          {{ $t('personal_information.role_preference_employee') }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value d-flex">
        <b-badge
          v-for="(item, index) in dataEmployee.role_preference"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{ item.name }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.services" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.services_employee') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value d-flex">
        <b-badge
          v-for="(item, index) in dataEmployee.services"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{ item.name }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row v-if="dataEmployee.experience" class="package-details-table__body">
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">
          {{ $t('personal_information.experience_employee') }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{
            experienceList.filter(
              (item) => item.id === dataEmployee.experience
            )[0][`name_` + $route.params.lang]
          }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.linkedin_account"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('personal_information.linkedin_account') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0" dir="ltr">
          {{ dataEmployee.linkedin_account }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.bank_account_number"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">
          {{ $t('personal_information.bank_account_number') }}:
        </p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <p class="mb-0">
          {{ dataEmployee.bank_account_number }}
        </p>
      </b-col>
    </b-row>
    <!-- <b-row
      v-if="dataEmployee.contract_file"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.contract_file') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0">
          <label @click="openContractFile(dataEmployee.contract_file)"
            ><open-file-icon
          /></label>
        </div>
      </b-col>
    </b-row> -->
    <b-row
      v-if="dataEmployee.cv && dataEmployee.cv.file_name"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.cv') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0">
          <label class="open-file-icon" @click="openCV(dataEmployee.cv)">
            <span v-if="dataEmployee.cv.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  dataEmployee.cv
                    ? dataEmployee.cv.name.split('_')[0]
                    : dataEmployee.cv
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ dataEmployee.cv.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="dataEmployee.work_proof && dataEmployee.work_proof.file_name"
      class="package-details-table__body"
    >
      <b-col sm="3" class="package-details-name">
        <p class="mb-0">{{ $t('users_data.freelance_certificate') }}:</p>
      </b-col>
      <b-col sm="9" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0">
          <label
            class="open-file-icon"
            @click="openWorkProof(dataEmployee.work_proof)"
          >
            <span v-if="dataEmployee.work_proof.type == 'pdf'">
              <pdf-icon
            /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  dataEmployee.work_proof
                    ? dataEmployee.work_proof.name.split('_')[0]
                    : dataEmployee.work_proof
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ dataEmployee.work_proof.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <hr
      v-if="dataEmployee.status == 1 || dataEmployee.status == 2"
      class="m-0"
    />
    <b-row
      v-if="
        dataEmployee.status == 1 ||
        dataEmployee.status == 2 ||
        dataEmployee.status == 0
      "
      class=""
    >
      <b-col sm="12" class="package-details-name py-5 d-flex p-0-30">
        <wameed-btn
          :classes="
            dataEmployee.status == 2
              ? 'text-med-14 text-white rounded-10 mx-md-5 mx-0 min-width-96'
              : 'text-med-14 text-white rounded-10 mx-md-5 mx-0'
          "
          :title="
            dataEmployee.status == 1
              ? $t('common.stopping')
              : $t('common.activation')
          "
          type="button"
          variant="main"
          @submitAction="
            dataEmployee.subtype == 'business' && dataEmployee.status != 2
              ? (showChangeBusinessStatus = true)
              : (showChangeEmployeesStatus = true)
          "
        />
        <wameed-btn
          v-if="dataEmployee.status == 2"
          :classes="
            dataEmployee.status == 2
              ? 'text-med-14 text-white rounded-10 mx-md-5 mx-3 min-width-96'
              : 'text-med-14 text-white rounded-10 mx-md-5 mx-3'
          "
          :title="$t('common.refusal')"
          type="button"
          variant="danger"
          @submitAction="showRejectedUser = true"
        />
        <wameed-btn
          :classes="
            dataEmployee.status == 2
              ? 'ml-auto mr-md-5 mr-0 text-med-14 text-font-secondary min-width-96'
              : 'ml-auto mr-md-5 mr-0 text-med-14 text-font-secondary'
          "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          onclick="history.back()"
        />
      </b-col>
    </b-row>
    <warning-modal
      v-if="dataEmployee.subtype == 'employee'"
      :variant="dataEmployee.status == 1 ? 'danger' : 'main'"
      :variant2="dataEmployee.status == 1 ? 'danger' : 'success'"
      :icon-name="
        dataEmployee.status == 1 ? 'stopping-icon' : 'confirmation-icon'
      "
      :visible="showChangeEmployeesStatus"
      :title="
        dataEmployee.status == 1
          ? $t('common.stopping_user_title')
          : $t('common.activation_user_title')
      "
      :sub-title="
        dataEmployee.status == 1
          ? $t('common.stopping_user_subtitle')
          : $t('common.activation_user_subtitle')
      "
      :btn-title="
        dataEmployee.status == 1
          ? $t('common.stopping')
          : $t('common.activation')
      "
      @close="showChangeEmployeesStatus = false"
      @submitAction="
        dataEmployee.status == 1
          ? stopUser(dataEmployee.subtype)
          : activateUser(dataEmployee.subtype)
      "
    />
    <warning-modal
      v-if="dataEmployee.subtype == 'business'"
      :variant="dataEmployee.status == 1 ? 'danger' : 'main'"
      :variant2="dataEmployee.status == 1 ? 'danger' : 'success'"
      :icon-name="
        dataEmployee.status == 1 ? 'stopping-icon' : 'confirmation-icon'
      "
      :visible="showChangeBusinessStatus"
      :title="
        dataEmployee.status == 1
          ? $t('common.stopping_user_title')
          : $t('common.activation_user_title')
      "
      :sub-title="
        dataEmployee.status == 1
          ? $t('common.stopping_user_subtitle')
          : $t('common.activation_user_subtitle')
      "
      :btn-title="
        dataEmployee.status == 1
          ? $t('common.stopping')
          : $t('common.activation')
      "
      @close="showChangeBusinessStatus = false"
      @submitAction="
        dataEmployee.status == 1
          ? stopUser(dataEmployee.subtype)
          : activateUser(dataEmployee.subtype)
      "
    />
    <validation-observer
      v-if="dataEmployee.subtype == 'business'"
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
    >
      <b-modal
        id="wameed-modal"
        v-model="showChangeEmployeesStatus"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        scrollable
        hide-header
      >
        <div class="align-items-center w-100">
          <div
            class="
              d-flex
              flex-column
              justify-content-between
              align-items-center
              w-75
              mx-auto
              mt-5
            "
          >
            <b-avatar :variant="'light-success'" rounded size="76" class="mb-5">
              <confirmation-icon />
            </b-avatar>

            <h4 class="text-med-20 py-0 text-dark">
              {{ $t('common.activation_user_title') }}
            </h4>
            <h4 class="text-reg-16 py-0 text-center text-font-secondary">
              {{ $t('common.activation_business_subtitle') }}
            </h4>
          </div>
        </div>
        <wameed-form refs="addOfferForm">
          <template slot="form-content" slot-scope="{ invalid }">
            <div class="py-1 px-1">
              <div @click="$refs.imageinput.click()">
                <text-input
                  id="contract_before_signing"
                  v-model="contract_before_signing"
                  class="upload_image"
                  readonly
                  name="contract_before_signing"
                  input-classes="text-med-14 text-font-main"
                  rules="required"
                  :label="$t('common.contract')"
                  :placeholder="$t('common.contract_placeholder')"
                  is-append
                  append-class="append-btn"
                  :append-text="$t('orders_page.browse')"
                />
              </div>
              <input
                ref="imageinput"
                type="file"
                accept="application/pdf, application/docs"
                style="display: none"
                @change="uploadImageAction($event)"
              />
            </div>
          </template>
        </wameed-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center w-100">
            <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('common.activation')"
              :disabled="invalid"
              type="submit"
              variant="main"
              @submitAction="activateUser(dataEmployee.subtype)"
            />
            <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="showChangeEmployeesStatus = false"
            />
          </div>
        </template>
      </b-modal>
    </validation-observer>
    <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
    >
      <b-modal
        id="wameed-modal"
        v-model="showRejectedUser"
        modal-class="not-m-modal"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        scrollable
        hide-header
      >
        <div class="align-items-center w-100">
          <div
            class="
              d-flex
              flex-column
              justify-content-between
              align-items-center
              w-75
              mx-auto
              mt-5
            "
          >
            <b-avatar :variant="'light-danger'" rounded size="76" class="mb-5">
              <rejected-icon />
            </b-avatar>

            <h4 class="text-med-20 py-0 text-dark">
              {{ $t('common.rejected_user_title') }}
            </h4>
            <h4 class="text-reg-16 py-0 text-center text-font-secondary">
              {{ $t('common.rejected_user_subtitle') }}
            </h4>
          </div>
        </div>
        <wameed-form refs="addOfferForm">
          <template slot="form-content" slot-scope="{ invalid }">
            <div class="py-1 px-1">
              <wameed-dropdown
                id="fields"
                v-model="fields"
                :label="$t('common.fields')"
                :placeholder="$t('common.fields_placeholder')"
                classes="text-med-16 w-100"
                value-classes=" text-med-14 text-font-main"
                title="name"
                variant="disable"
                :items="
                  dataEmployee.subtype == 'business'
                    ? fieldsCompanyList
                    : fieldsEmployeeList
                "
                text="name"
                name="fields"
                rules="required"
                :multiple="true"
              />
            </div>
            <div class="py-1 px-1">
              <text-area
                id="new-password"
                v-model="rejectedDescription"
                :label="$t('common.reason_refuse')"
                field-classes="mb-0"
                input-classes="text-med-14 text-font-main"
                name="rejectedDescription"
                rules="required"
                :placeholder="$t('common.reason_refuse_placeholder')"
                rows="5"
              />
            </div>
          </template>
        </wameed-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center w-100">
            <wameed-btn
              classes="text-med-14 text-white rounded-10"
              :title="$t('common.refusal')"
              :disabled="invalid"
              type="submit"
              variant="danger"
              @submitAction="rejectedUser(dataEmployee.subtype)"
            />
            <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="hideRejectedUser()"
            />
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import WarningModal from '@/components/WarningModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import TextInput from '@/components/TextInput.vue';
import TextArea from '../../TextArea.vue';

export default {
  components: {
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
    WameedDropdown,
    TextInput,
  },
  props: {
    dataEmployee: {
      type: Object,
      default: null,
    },

    badge: {
      type: Object,
      default() {
        return {
          name: 'مقبول',
          color: 'success',
        };
      },
    },
  },
  data() {
    return {
      companySizeList: [
        {
          id: 1,
          name_ar: 'شركة ناشئة',
          name_en: 'Start-up company',
        },
        {
          id: 2,
          name_ar: 'شركة متوسطة',
          name_en: 'Med size compay',
        },
        {
          id: 3,
          name_ar: 'المؤسسات / شركة كبرى',
          name_en: 'Enterprise / Corperates ',
        },
      ],
      experienceList: [
        {
          id: 1,
          name_ar: 'من سنة إلى 3 سنوات',
          name_en: '1 - 3 years',
        },
        {
          id: 2,
          name_ar: 'من 3 إلى 5 سنوات',
          name_en: '3 - 5 Years',
        },
        {
          id: 3,
          name_ar: 'من 5 إلى 7 سنوات',
          name_en: '5 - 7 years ',
        },
        {
          id: 4,
          name_ar: 'من 7 إلى 10 سنوات',
          name_en: '7 - 10 years',
        },
        {
          id: 5,
          name_ar: 'أكثر من 10 سنوات',
          name_en: 'More than 10 years',
        },
      ],
      languageSkillsList: [
        {
          id: 1,
          name_ar: 'اللغة العربية',
          name_en: 'Arabic',
        },
        {
          id: 2,
          name_ar: 'اللغة الإنجليزية',
          name_en: 'English',
        },
        {
          id: 3,
          name_ar: 'اللغة الفرنسية',
          name_en: 'French',
        },
        {
          id: 4,
          name_ar: 'اللغة الصينية',
          name_en: 'Chinese',
        },
        {
          id: 5,
          name_ar: 'اللغة الأسبانية',
          name_en: 'Spanish',
        },
        {
          id: 6,
          name_ar: 'اللغة ألمانية',
          name_en: 'German',
        },
        {
          id: 7,
          name_ar: 'اللغة الإيطالية',
          name_en: 'Italian',
        },
        {
          id: 8,
          name_ar: 'اللغة الهندية',
          name_en: 'Hindi',
        },
        {
          id: 9,
          name_ar: 'اللغة الأردية',
          name_en: 'Urdu',
        },
        {
          id: 10,
          name_ar: 'اللغة البنغالية',
          name_en: 'Bengali',
        },
        {
          id: 11,
          name_ar: 'اللغة البرتغالية',
          name_en: 'Portuguese',
        },
        {
          id: 12,
          name_ar: 'اللغة الروسية',
          name_en: 'Russian',
        },
        {
          id: 13,
          name_ar: 'اللغة اليابانية',
          name_en: 'Japanese',
        },
        {
          id: 14,
          name_ar: 'اللغة الكورية',
          name_en: 'Korean',
        },
      ],
      items: [{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }],
      showChangeEmployeesStatus: false,
      showChangeBusinessStatus: false,
      showRejectedUser: false,
      rejectedDescription: '',
      contract_before_signing: null,
      fields: [],
      fieldsEmployeeList: [
        {
          id: 1,
          key: 'name',
          name: this.$i18n.t('users_data.name'),
        },
        {
          id: 2,
          key: 'job_title',
          name: this.$i18n.t('users_data.job_title'),
        },
        {
          id: 3,
          key: 'phone',
          name: this.$i18n.t('users_data.phone'),
        },
        {
          id: 4,
          key: 'linkedin_account',
          name: this.$i18n.t('personal_information.linkedin_account'),
        },
        {
          id: 5,
          key: 'bank_account_number',
          name: this.$i18n.t('personal_information.bank_account_number'),
        },
        {
          id: 6,
          key: 'cv',
          name: this.$i18n.t('orders_page.cv'),
        },
        {
          id: 7,
          key: 'work_proof',
          name: this.$i18n.t('users_data.freelance_certificate'),
        },
      ],
      fieldsCompanyList: [
        {
          id: 1,
          key: 'name',
          name: this.$i18n.t('users_data.name'),
        },
        {
          id: 2,
          key: 'phone',
          name: this.$i18n.t('users_data.phone'),
        },
        {
          id: 3,
          key: 'name',
          name: this.$i18n.t('personal_information.commercial_name'),
        },
        {
          id: 4,
          key: 'tax_number',
          name: this.$i18n.t('personal_information.tax_number'),
        },

        {
          id: 5,
          key: 'company_size',
          name: this.$i18n.t('personal_information.company_size'),
        },
        {
          id: 6,
          key: 'number_employees',
          name: this.$i18n.t('personal_information.number_employees'),
        },
      ],
      statusList: [
        {
          0: 'inactive',
          1: 'active',
          2: 'account_under_review',
          3: 'not_completed_data',
          4: 'contract_before_signing',
          5: 'incorrect_data',
          6: 'contract_after_signing',
        },
        {
          0: 'icon',
          1: 'success',
          2: 'warning',
          3: 'warning',
          4: 'warning',
          5: 'danger',
          6: 'info',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      changeEmployeesStatus: 'changeEmployeesStatus',
      changeCompaniesStatus: 'changeCompaniesStatus',
      uploadFiles: 'uploadFiles',
    }),
    hideRejectedUser() {
      this.showRejectedUser = false;
      this.fields = [];
      this.rejectedDescription = '';
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    openCV(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    openWorkProof(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    openContractFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    cancelOrder() {
      this.$router.push({
        name: 'users-employees',
        params: { lang: this.$i18n.locale },
      });
    },
    rejectedUser(subtype) {
      if (subtype === 'employee') {
        this.changeEmployeesStatus({
          id: this.$router.currentRoute.params.id,
          status: 5,
          description: this.rejectedDescription,
          fields: this.fields,
        }).then(() => {
          this.showRejectedUser = false;
          this.$router.push({
            name: 'users-new-accounts',
            params: { lang: this.$i18n.locale },
          });
        });
      } else if (subtype === 'business') {
        this.changeCompaniesStatus({
          id: this.$router.currentRoute.params.id,
          status: 5,
          description: this.rejectedDescription,
          fields: this.fields,
        }).then(() => {
          this.showRejectedUser = false;
          this.$router.push({
            name: 'users-new-accounts',
            params: { lang: this.$i18n.locale },
          });
        });
      }
    },
    stopUser(subtype) {
      if (subtype === 'employee') {
        this.changeEmployeesStatus({
          id: this.$router.currentRoute.params.id,
          status: 0,
        }).then(() => {
          this.showChangeBusinessStatus = false;
          this.showChangeEmployeesStatus = false;
          this.$router.push({
            name: 'users',
            params: { lang: this.$i18n.locale },
          });
        });
      } else if (subtype === 'business') {
        this.changeCompaniesStatus({
          id: this.$router.currentRoute.params.id,
          status: 0,
        }).then(() => {
          this.showChangeBusinessStatus = false;
          this.showChangeEmployeesStatus = false;
          this.$router.push({
            name: 'users',
            params: { lang: this.$i18n.locale },
          });
        });
      }
    },
    activateUser(subtype) {
      if (subtype === 'employee') {
        this.changeEmployeesStatus({
          id: this.$router.currentRoute.params.id,
          status: 1,
        }).then(() => {
          this.showChangeBusinessStatus = false;
          this.showChangeEmployeesStatus = false;
          this.$router.push({
            name: 'users',
            params: { lang: this.$i18n.locale },
          });
        });
      } else if (subtype === 'business') {
        if (this.dataEmployee.status === 0) {
          this.changeCompaniesStatus({
            id: this.$router.currentRoute.params.id,
            status: 1,
          }).then(() => {
            this.showChangeBusinessStatus = false;
            this.showChangeEmployeesStatus = false;
            this.$router.push({
              name: 'users',
              params: { lang: this.$i18n.locale },
            });
          });
        } else {
          this.changeCompaniesStatus({
            id: this.$router.currentRoute.params.id,
            status: 4,
            contract_before_signing: this.contract_before_signing,
          }).then(() => {
            this.showChangeBusinessStatus = false;
            this.showChangeEmployeesStatus = false;
            this.$router.push({
              name: 'users',
              params: { lang: this.$i18n.locale },
            });
          });
        }
      }
    },
    uploadImageAction(event, type = 'upload') {
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        this.uploadFiles(formData).then((response) => {
          this.contract_before_signing = response.content.file_name;
        });
      }
    },
    jsonParseCountry(json) {
      return JSON.parse(json).name;
    },
  },
};
</script>
